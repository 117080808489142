// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

import { api } from '@/api'

export async function getAvailablePublicIpAddresses (zoneid, domainid, account) {
  const apiResult = await new Promise((resolve, reject) => {
    const params = {
      zoneid,
      domainid,
      account,
      forvirtualnetwork: true,
      allocatedonly: false
    }
    api('listPublicIpAddresses', params).then(json => {
      const listPublicIps = json.listpublicipaddressesresponse.publicipaddress || []
      resolve(listPublicIps)
    }).catch(reject)
  })

  const listPublicIpAddress = apiResult.filter(item => item.state === 'Free')

  listPublicIpAddress.sort(function (a, b) {
    if (a.ipaddress < b.ipaddress) { return -1 }
    if (a.ipaddress > b.ipaddress) { return 1 }
    return 0
  })

  return listPublicIpAddress
}
